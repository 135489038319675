import React from 'react';
import {graphql, StaticQuery} from 'gatsby';
import ArticlePreview from '../../templates/articlePreview';
import cx from 'classnames';

class VideoPopularPosts extends React.Component {

  constructor (props) {
    super(props);
    this.className = cx(
      'popular-posts',
      props.className
    );
  }

  /*componentDidMount() {
      addTimeout(() => {
          try {
              googletag.cmd.push(function () {
                  googletag.display('div-pt_rightcol_desktop');
              });
          } catch (e) {
              console.log(e);
          }
      }, adDisplayDelay() * delayMultiplicationFactor(isMobile));
  }*/

  renderItems () {
    return <StaticQuery
      query={graphql`
            query VideoPopularPostsQuery {
                allArticles: allDatoCmsArticle(filter: {category: {elemMatch: {originalId: {nin: ["39161838"]}}}, website: {elemMatch: {name: {eq: "pt"}}}}
                    sort: {publishedAt: DESC}
                    limit: 5
                    skip: 40
                ) {
                    nodes {
                        ...ArticleFieldsPlugin
                    }
                }
            }
        `}
      render={data =>
        data.allArticles.nodes.map((article, index) => {
          return <div key={`pp-${index}`}>
            <div>
              <ArticlePreview className="border-b-default border-gray-400 mb-2 pb-2"
                              data={article}
                              headingClass={'text-xl font-arial leading-snug'}
                              noBody={true}
                              noFlag={true}
                              noAuthor={true}
                              publishedAtFormat={'mmmm dd, yyyy'}/>
            </div>
            {
              {
                1: <div className="ad-container mb-2">
                  <div className="advertisement-text">Advertisement</div>
                  <div id="pt_rightcol_desktop"></div>
                </div>
              }[index]
            }
          </div>;
        })
      }
    />;
  }

  render () {
    return <div className={this.className} style={{minWidth: '330px'}}>
      {this.renderItems()}
    </div>;
  }
}

export default VideoPopularPosts;
